
import { BsFillTelephoneFill } from "react-icons/bs";
import { FaWhatsapp } from "react-icons/fa"; 
import React from "react";
import Brand from "./../../assets/brand.svg";
import { Container, Col, Row } from "react-bootstrap";
import "./Footer.css";

const FooterComponent = () => {
  return (
    <section className="footer-bg mt-5">
      <Container>
        <Row>
          <div className="footer-container">
            <Col md={5}>
              <div className="footer-follow">
                <div className="logo-container">
              <a style={{ textDecoration: "none" }} href="/#home">
                <img src={Brand} alt="Brand Logo" className="logo-img " />
                </a>
                </div>
                <div className="footer-icon-link">
                    Infinite Tech Innovations.Corp
                  </div>
                  <div className="footer-address ">
                  Ottawa, Ontario, Canada
                  </div>
                  <div className="contact-num ">
                    <BsFillTelephoneFill className="icons" />
                    <a href="tel:+13435585696" className="icon-text">
                      +1 (343) 558-5696
                    </a>
                  </div>
                  <div className="whatsapp-contact">
                    <FaWhatsapp className="icons" />
                    <a
                      href="https://wa.me/13435585696"
                      target="_blank"
                      rel="noopener noreferrer"
                      className="icon-text ml-2"
                    >
                      Contact us on WhatsApp
                    </a>
                  </div>
                </div>
            </Col>
            <Col md={3}>
              <div className="footer-follow">
                <a style={{ textDecoration: "none" }} href="/#home">
                  <span className="follow-home ">Links</span>
                </a>
                <div className="footer-icon-link">
                  <a href="#home" className="icon-text">
                    Home
                  </a>
                </div>
                <div className="footer-icon-link">
                  <a href="#services" className="icon-text">
                    Services
                  </a>
                </div>
                <div className="footer-icon-link">
                  <a href="#about" className="icon-text">
                    About Us
                  </a>
                </div>
                <div className="footer-icon-link">
                  <a href="#contact" className="icon-text">
                    Contact Us
                  </a>
                </div>
              </div>
            </Col>
            <Col md={4}>
              <div className="footer-meetus">
                <span className="follow-text">Our Services</span>
                <div className="footer-icon-link">
                  <div className="icon-text">Application Development</div>
                </div>
                <div className="footer-icon-link">
                  <div className="icon-text">Web Development</div>
                </div>
                <div className="footer-icon-link">
                  <div className="icon-text">Digital Marketing</div>
                </div>

                <div className="footer-icon-link">
                  <div className="icon-text">
                    Internet of Things (IOT) Development
                  </div>
                </div>
              </div>
            </Col>
            <Row></Row>
          </div>
        </Row>
      </Container>
    </section>
  );
};

export default FooterComponent;
