import React from "react";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import aboutImg from "./../../assets/about-img.png";
import "./about.css";

const About = () => {
  return (
    <section id="about">
    <Container>
 
        <div className="sectionTitle">About Us</div>


      <Row className="mb-4">
        <Col lg={7}>
          <img
            src={aboutImg}
            alt="Application Development"
            className="about-image"
          />
        </Col>
        <Col lg={5}>
          <div className="about-content">
            At Infinite Tech Innovations Corp., we are a dynamic software
            development company dedicated to delivering innovative and scalable
            technology solutions that drive business growth and digital
            transformation. With a passionate team of skilled developers,
            designers, and technology experts, we specialize in creating custom
            software, web, and mobile applications tailored to meet the unique
            needs of our clients across various industries. Our mission is to
            empower businesses by leveraging cutting-edge technology, fostering
            creativity, and ensuring exceptional quality in everything we build.
            We believe in a collaborative approach, working closely with our
            clients to understand their vision and turning it into reality with
            robust, reliable, and user-friendly software solutions. At the heart
            of our operations is a commitment to excellence, innovation, and
            customer satisfaction, which propels us to constantly evolve and
            deliver beyond expectations.
          </div>
        </Col>
      </Row>
    </Container>
    </section>
  );
};
export default About;
