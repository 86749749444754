import './Banner.css'
import React from 'react';
import BannerImager from './../../assets/banner1.jpg'

function Banner() {
    return (
        <div id='#home' className='banner'>
            <img src={BannerImager} className='img-fluid'></img>
            
            <div className='hero-text'>Comprehensive Web and Mobile Development Services Tailored to Your Needs</div>
            </div>
    );
}

export default Banner;