import "./Services.css";
import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import serviceImgOne from "./../../assets/service-img1.png";
import serviceImgTwo from "./../../assets/service-img2.png";
import serviceImgThree from "./../../assets/service-img3.png";
import serviceImgFour from "./../../assets/service-img4.png";
function Services() {
  return (
    <section id="services">
      <Container>
        <div className="sectionTitle">Services</div>

        <Row className="mb-4">
          <Col lg={6}>
            <div className="card m-4">
              <img
                src={serviceImgOne}
                alt="Application Development"
                className="service-image"
              />
              <div className="service-name p-4">Application Development</div>
              <div className="service-desc p-4">
                At Infinite Tech Innovations Corp., we offer comprehensive
                mobile app development services to bring your ideas to life on
                iOS and Android platforms. Our expert team specializes in custom
                app development, cross-platform solutions using frameworks like
                Flutter and React Native, and intuitive UI/UX design to create
                engaging user experiences. From initial concept to deployment
                and ongoing support, we handle the entire development process,
                ensuring seamless integration, high performance, and a
                successful launch. Whether you need a native app or a
                cost-effective cross-platform solution, we deliver apps that
                drive results and help your business thrive in the digital
                landscape.
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="card m-4">
              <img
                src={serviceImgTwo}
                alt="Application Development"
                className="service-image"
              />
              <div className="service-name p-4">Web Development</div>
              <div className="service-desc p-4">
                At Infinite Tech Innovations Corp., we offer comprehensive
                website development services to help businesses establish a
                strong online presence. Our team specializes in creating custom,
                responsive, and user-friendly websites that are optimized for
                performance and SEO. We develop everything from dynamic web
                applications to e-commerce platforms and CMS-based websites,
                ensuring seamless functionality and integration with third-party
                tools. With a focus on high-quality design, robust development,
                and ongoing support, we deliver websites that not only look
                great but also drive results and enhance user engagement.
              </div>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={6}>
            <div className="card m-4">
              <img
                src={serviceImgThree}
                alt="Application Development"
                className="service-image"
              />
              <div className="service-name p-4">Digital Marketing</div>
              <div className="service-desc p-4">
              At Infinite Tech Innovations Corp., we offer comprehensive mobile app development services to bring your ideas to life on iOS and Android platforms. Our expert team specializes in custom app development, cross-platform solutions using frameworks like Flutter and React Native, and intuitive UI/UX design to create engaging user experiences. From initial concept to deployment and ongoing support, we handle the entire development process, ensuring seamless integration, high performance, and a successful launch. Whether you need a native app or a cost-effective cross-platform solution, we deliver apps that drive results and help your business thrive in the digital landscape.
              </div>
            </div>
          </Col>
          <Col lg={6}>
            <div className="card m-4">
              <img
                src={serviceImgFour}
                alt="Application Development"
                className="service-image"
              />
              <div className="service-name p-4">Internet of Things (IOT) Development</div>
              <div className="service-desc p-4">
              At Infinite Tech Innovations Corp., we offer comprehensive mobile app development services to bring your ideas to life on iOS and Android platforms. Our expert team specializes in custom app development, cross-platform solutions using frameworks like Flutter and React Native, and intuitive UI/UX design to create engaging user experiences. From initial concept to deployment and ongoing support, we handle the entire development process, ensuring seamless integration, high performance, and a successful launch. Whether you need a native app or a cost-effective cross-platform solution, we deliver apps that drive results and help your business thrive in the digital landscape.
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
}

export default Services;
