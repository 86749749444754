import React, { useState } from 'react';
import { Col, Row, Container, Image } from "react-bootstrap";
import ContactImage from './../../assets/contactus.png';
import { TextField, Button } from '@mui/material';
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    subject: '',
    message: '',
  });
  
  const [errors, setErrors] = useState({
    fullName: '',
    email: '',
    phoneNumber: '',
    subject: '',
    message: '',
  });

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrors({ ...errors, [name]: "" });
  };

  const validateForm = () => {
    let valid = true;
    const newErrors = {};

    if (!formData.fullName.trim()) {
      newErrors.fullName = 'Full Name is required';
      valid = false;
    }
    if (!formData.email.trim() || !/\S+@\S+\.\S+/.test(formData.email)) {
      newErrors.email = 'Valid Email is required';
      valid = false;
    }
    if (!formData.phoneNumber.trim() || !/^\d+$/.test(formData.phoneNumber)) {
      newErrors.phoneNumber = 'Valid Phone Number is required';
      valid = false;
    }

    setErrors(newErrors);
    return valid;
  };

  const handleSubmit = () => {
    if (validateForm()) {
      const { fullName, phoneNumber, subject, message } = formData;
  
  
      const messageToSend = `Hi, I'm interested in ${subject}. My name is ${fullName} and my phone number is ${phoneNumber}. ${message}`;
      
     
      const encodedMessage = encodeURIComponent(messageToSend);
      

      const whatsappLink = `https://api.whatsapp.com/send?phone=13435585696&text=${encodedMessage}`;
  
    
      console.log('Encoded Message:', encodedMessage);
      console.log('WhatsApp Link:', whatsappLink);
  
     
      window.open(whatsappLink, '_blank');
  

      setFormData({
        fullName: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: '',
      });
      setErrors({
        fullName: '',
        email: '',
        phoneNumber: '',
        subject: '',
        message: '',
      });
    }
  };
  

  return (
    <Container>
      <section id="contact">
        <div className="sectionTitle">Contact Us</div>
        <Row>
          <Col lg={6} className="contact-img-container">
            <Image src={ContactImage} className='conatct-img' />
            <div className="text-center contact-p">
              <div>
                For further inquiries, please don't <br />
                hesitate to get in touch with us.
              </div>
            </div>
          </Col>

          <Col lg={6}>
            <div className="contact-form">
              <TextField
                name="fullName"
                id="fullName"
                label="Full Name"
                variant="filled"
                fullWidth
                className="contact-form-textfield"
                value={formData.fullName}
                onChange={handleChange}
                error={!!errors.fullName}
                helperText={errors.fullName}
              />
              <TextField
                name="email"
                id="email"
                label="Email"
                variant="filled"
                fullWidth
                className="contact-form-textfield"
                value={formData.email}
                onChange={handleChange}
                error={!!errors.email}
                helperText={errors.email}
              />
              <TextField
                name="phoneNumber"
                id="phoneNumber"
                label="Phone Number"
                variant="filled"
                fullWidth
                className="contact-form-textfield"
                value={formData.phoneNumber}
                onChange={handleChange}
                error={!!errors.phoneNumber}
                helperText={errors.phoneNumber}
              />
              <TextField
                name="subject"
                id="subject"
                label="Subject"
                variant="filled"
                fullWidth
                className="contact-form-textfield"
                value={formData.subject}
                onChange={handleChange}
                error={!!errors.subject}
                helperText={errors.subject}
              />
              <TextField
                name="message"
                id="message"
                label="Message"
                multiline
                rows={4}
                variant="filled"
                fullWidth
                className="contact-form-textfield"
                value={formData.message}
                onChange={handleChange}
                error={!!errors.message}
                helperText={errors.message}
              />
              <Button
                className="contact-form-button"
                variant="contained"
                fullWidth
                sx={{ marginTop: 2 }}
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          </Col>
        </Row>
      </section>
    </Container>
  );
};

export default Contact;
