import logo from './logo.svg';
import './App.css';
import Header from './components/header/Header.jsx'
import Banner from './components/banner/Banner.jsx'
import Services from './components/services/Services.jsx'
import  About  from './components/about/about.jsx';
import Contact from './components/contact/Contact.jsx';
import Footer from './components/footer/Footer.jsx';


function App() {
  return (
    <div className="App">
      <Header/>
      <Banner/>
      <Services/>
      <About/>
      <Contact/>
      <Footer/>
    </div>
  );
}

export default App;
